


const Shapes = () => {
    return(
        <div className="shape-component">
            <div className="yellow-shape-container shape-container">
                <svg id="yellow-shape" className="colored-shape" width="189" height="275" viewBox="0 0 189 275" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M121.432 10.395C80.813 13.9331 63.7903 21.3803 39.2174 40.1625C23.7119 54.2663 12.5528 69.93 9.44985 90.72C7.08735 106.549 16.5373 129.938 22.2074 138.915C22.2074 138.915 31.8095 175.627 25.5149 188.528C19.2202 201.428 13.8964 209.424 7.08735 224.438C4.18552 233.939 5.19737 242.865 13.2298 249.953C22.2074 260.348 46.5411 270.27 59.5348 270.27C81.2698 267.671 95.8492 266.938 116.707 257.513C136.364 244.954 144.902 236.651 156.87 220.185C165.035 208.436 167.022 199.494 165.847 179.078C165.457 161.341 159.635 140.805 160.65 129.938C162.097 114.441 165.395 106.987 174.825 95.445C189.109 77.9625 186.953 59.494 182.385 44.415C169.161 22.4908 147.184 10.395 121.432 10.395Z" fill="#FFC906"/>
                </svg>
            </div>
            
            <div className="green-shape-container shape-container">
                <svg id="green-shape" className="colored-shape" width="300" height="280" viewBox="0 0 300 280" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.4641 40.5846C61.6301 14.6585 92.9813 0 125.657 0C153.082 0 167.735 5.42111 175.839 21.3833C179.119 27.4224 180.249 31.1787 178.756 39.7118C177.005 53.5744 178.154 61.0969 183.424 74.1868C192.822 87.3694 197.436 92.3176 207.931 100.37C236.775 119.597 238.445 118.263 258.696 129.609C301.501 148.233 301.875 196.813 298.957 207.723C296.04 218.633 281.238 250.022 232.438 268.818C195.141 285 112.964 278.855 102.901 278.855C78.3938 278.855 57.9713 257.036 65.5567 240.889C72.5868 225.925 67.172 217.329 65.5567 200.741C60.6972 181.816 51.7345 173.152 33.4641 158.411C9.10938 138.561 0.204339 111.28 0.204523 106.916C-2.12926 95.1337 15.9186 59.6044 33.4641 40.5846Z" fill="#5CC1A5"/>
                </svg>
            </div>
            
  
            <div className="red-shape-container shape-container">
                <svg id="red-shape" className="colored-shape" width="291" height="227" viewBox="0 0 291 227" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.46815 83.3728C12.8164 67.2615 18.4901 57.2285 31.2247 42.3662C47.3067 26.293 60.7873 21.3938 82.2952 10.6206C88.8419 8.41411 133.889 -2.37787 157.731 1.92012C186.032 7.14629 204.857 21.3001 240.062 52.7327C255.692 68.0803 262.928 76.5382 273.378 91.4128C283.941 105.657 287.557 112.976 289.497 124.967C291.649 136.668 289.699 145.81 282.395 165.245C271.855 189.669 261.632 205.905 245.924 214.652C243.352 216.084 225.991 230.151 191.854 225.71C147.75 214.952 165.255 167.434 117.108 167.321C100.167 166.009 45.0156 222.043 4.37841 135.518C-2.80027 120.233 1.73734 101.028 6.46815 83.3728Z" fill="#EF4852"/>
                </svg>
            </div>
            
        </div>
    )
}

export default Shapes;