


const Loader = () => {
    return (
        <div className="loader-wrapper">

            <svg id="red-shape" width="42" height="32" viewBox="0 0 42 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.62226 11.6945C2.51027 9.44076 3.30392 8.03731 5.08528 5.95832C7.33488 3.70996 9.22059 3.02465 12.2292 1.51765C13.145 1.209 19.4462 -0.300616 22.7813 0.3006C26.7402 1.03165 29.3736 3.01154 34.298 7.40843C36.4844 9.55529 37.4966 10.7384 38.9584 12.8191C40.436 14.8116 40.9418 15.8354 41.2132 17.5127C41.5143 19.1496 41.2415 20.4283 40.2197 23.147C38.7454 26.5635 37.3154 28.8346 35.1181 30.0582C34.7583 30.2585 32.3298 32.2262 27.5546 31.605C21.3852 30.1002 23.8339 23.4532 17.0989 23.4374C14.7291 23.2539 7.0144 31.0921 1.32994 18.9887C0.325762 16.8506 0.960498 14.1642 1.62226 11.6945Z" fill="#EF4852"/>
            </svg>
        

            <svg id="green-shape" width="42" height="32" viewBox="0 0 42 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.76874 11.6945C2.65676 9.44076 3.4504 8.03731 5.23177 5.95832C7.48137 3.70996 9.36708 3.02465 12.3757 1.51765C13.2914 1.209 19.5927 -0.300616 22.9278 0.3006C26.8867 1.03165 29.5201 3.01154 34.4445 7.40843C36.6309 9.55529 37.6431 10.7384 39.1049 12.8191C40.5825 14.8116 41.0883 15.8354 41.3597 17.5127C41.6608 19.1496 41.388 20.4283 40.3662 23.147C38.8919 26.5635 37.4618 28.8346 35.2645 30.0582C34.9048 30.2585 32.4762 32.2262 27.7011 31.605C21.5316 30.1002 23.9804 23.4532 17.2454 23.4374C14.8756 23.2539 7.16089 31.0921 1.47642 18.9887C0.472247 16.8506 1.10698 14.1642 1.76874 11.6945Z" fill="#5CC1A5"/>
            </svg>
                
            <svg id="yellow-shape" width="42" height="32" viewBox="0 0 42 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.76874 11.6945C2.65676 9.44076 3.4504 8.03731 5.23177 5.95832C7.48137 3.70996 9.36708 3.02465 12.3757 1.51765C13.2914 1.209 19.5927 -0.300616 22.9278 0.3006C26.8867 1.03165 29.5201 3.01154 34.4445 7.40843C36.6309 9.55529 37.6431 10.7384 39.1049 12.8191C40.5825 14.8116 41.0883 15.8354 41.3597 17.5127C41.6608 19.1496 41.388 20.4283 40.3662 23.147C38.8919 26.5635 37.4618 28.8346 35.2645 30.0582C34.9048 30.2585 32.4762 32.2262 27.7011 31.605C21.5316 30.1002 23.9804 23.4532 17.2454 23.4374C14.8756 23.2539 7.16089 31.0921 1.47642 18.9887C0.472247 16.8506 1.10698 14.1642 1.76874 11.6945Z" fill="#FFC906"/>
            </svg>

        </div>
    )
}

export default Loader;